<template>
  <div class="grid">
		<div class="col-12">
            <div class="card" v-if="permission.includes('read')">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label >Username</label>
                                <InputText  v-model="forms.username" :class="{ 'p-invalid': errorAdd.username }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.username" >{{ errorAdd.username[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label >Name</label>
                                <InputText  v-model="forms.name" :class="{ 'p-invalid': errorAdd.name }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.name" >{{ errorAdd.name[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label >Password</label>
                                <Password  v-model="forms.password" :feedback="false" toggleMask :class="{ 'p-invalid': errorAdd.password }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.password" >{{ errorAdd.password[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label >Phone</label>
                                <InputText type="email" v-model="forms.phone" :class="{ 'p-invalid': errorAdd.phone }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.phone" >{{ errorAdd.phone[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label >Email</label>
                                <InputText  v-model="forms.email" :class="{ 'p-invalid': errorAdd.email }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.email" >{{ errorAdd.email[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label >Level</label>
                                <Dropdown v-model="forms.level_id" :loading="loadingDropdownLevelAdd" :options="dataDropdownLevelAdd" optionLabel="level_name" placeholder="Pilih Level" :class="{ 'p-invalid': errorAdd.level_id }" :filter="true" :showClear="true" @filter="searchDropdownLevel($event, 'add')" @change="changeSpvAdd()"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.level_id" >{{ errorAdd.level_id[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3" v-if="visibleSpvAdd">
                                <label >SPV Level</label>
                                <Dropdown v-model="forms.spv_type" :options="dataDropdownSupervisorType" optionLabel="name" optionValue="code" placeholder="Pilih SPV Level" :class="{ 'p-invalid': errorAdd.spv_type }" @change="changeSpvAdd()"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.spv_type" >{{ errorAdd.spv_type[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label>Supervisor</label>
                                <Dropdown v-model="forms.spv_id" :loading="loadingDropdownSupervisorAdd" :options="dataDropdownSupervisorAdd" optionLabel="label" optionValue="mcu_id" placeholder="Pilih Supervisor" :class="{ 'p-invalid': errorAdd.spv_id }" :filter="true" :showClear="true" @filter="searchDropdownSupervisor($event, 'add')" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.spv_id" >{{ errorAdd.spv_id[0] }}</small>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="userid" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="mcu_name" header="Nama">
                        <template #body="slotProps">
                            {{slotProps.data.mcu_name}}
                        </template>
                    </Column>
                    <Column field="mcu_phone" header="Phone">
                        <template #body="slotProps">
                            {{slotProps.data.mcu_phone}}
                        </template>
                    </Column>
                    <Column field="mcu_email" header="Email">
                        <template #body="slotProps">
                            {{slotProps.data.mcu_email}}
                        </template>
                    </Column>
                    <Column field="mcu_level_name" header="Level">
                        <template #body="slotProps">
                            {{slotProps.data.mcu_level_name}}
                        </template>
                    </Column>
                    <Column field="mcu_level_label" header="Supervisor">
                        <template #body="slotProps">
                            {{slotProps.data.mcu_level_label}}
                        </template>
                    </Column>
                    <Column field="spv_code" header="SPV Username">
                        <template #body="slotProps">
                            {{slotProps.data.spv_code}}
                        </template>
                    </Column>
                    <Column field="spv_name" header="SPV Name">
                        <template #body="slotProps">
                            {{slotProps.data.spv_name}}
                        </template>
                    </Column>
                    <Column field="mcu_status" header="Status">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success" v-if="slotProps.data.mcu_status == 'y'" />
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else/>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
                            <Button icon="pi pi-refresh" class="p-button-rounded p-button-danger mr-2" @click="resetItem(slotProps.data)" v-if="permission.includes('update')"/>
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)"  v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

                <Reset @submit="getDataTable()" :item="item" ref="reset"/>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label >Username</label>
                        <InputText  v-model="item.username" :class="{ 'p-invalid': errorEdit.username }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.username" >{{ errorEdit.username[0] }}</small >
					</div>
					<div class="field">
						<label >Name</label>
                        <InputText  v-model="item.mcu_name" :class="{ 'p-invalid': errorEdit.name }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.name" >{{ errorEdit.name[0] }}</small >
					</div>
					<div class="field">
						<label >Phone</label>
                        <InputText  v-model="item.mcu_phone" :class="{ 'p-invalid': errorEdit.phone }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.phone" >{{ errorEdit.phone[0] }}</small >
					</div>
					<div class="field">
						<label >Email</label>
                        <InputText  v-model="item.mcu_email" :class="{ 'p-invalid': errorEdit.email }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.email" >{{ errorEdit.email[0] }}</small >
					</div>
					<div class="field">
						<label >Level</label>
                        <Dropdown ref="dlevel" v-model="item.mcu_level_id" :loading="loadingDropdownLevelEdit" :options="dataDropdownLevelEdit" :class="{ 'p-invalid': errorEdit.level_id }" optionLabel="level_name" optionValue="level_id" placeholder="Pilih Level" :filter="true" :showClear="true" @filter="searchDropdownLevel($event, 'edit')" @change="changeSpvLevelEdit()"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.level_id" >{{ errorEdit.level_id[0] }}</small >
					</div>
                    <div class="field" v-if="visibleSpvEdit">
                        <label>SPV Level</label>
                        <Dropdown v-model="item.spv_type" :options="dataDropdownSupervisorType" optionLabel="name" optionValue="code" placeholder="Pilih SPV Level" :class="{ 'p-invalid': errorEdit.spv_type }" @change="changeSpvEdit()"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.spv_type" >{{ errorEdit.spv_type[0] }}</small >
                    </div>
                    <div class="field">
                        <label>Supervisor</label>
                        <Dropdown ref="dspv" v-model="item.spv_id" :loading="loadingDropdownSupervisorEdit" :options="dataDropdownSupervisorEdit" :class="{ 'p-invalid': errorEdit.spv_id }" optionLabel="label" optionValue="mcu_id" placeholder="Pilih Supervisor" :filter="true" :showClear="true" @filter="searchDropdownSupervisor($event, 'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.spv_id" >{{ errorEdit.spv_id[0] }}</small >
                    </div>
                    <div class="field">
                        <label>Status</label>
                        <Dropdown ref="dstatus" v-model="item.mcu_status"  :options="dataDropdownStatus" :class="{ 'p-invalid': errorEdit.status }" optionLabel="name" optionValue="code" placeholder="Pilih Status" :showClear="true"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.status" >{{ errorEdit.status[0] }}</small >
                    </div>
					<div class="field">
						<label>Password</label>
                        <Password id="password" v-model="password" :class="{ 'p-invalid': errorEdit.password }" toggleMask :feedback="false" />
                        <small>Silahkan isi jika ingin mengganti password</small ><br>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.password" >{{ errorEdit.password[0] }}</small >
					</div>
					<template #footer>
						<Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Reset from '../../components/master/user-hp/Reset.vue';

export default {
    components: {
		'Reset': Reset,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingDropdownSupervisorAdd: false,
            loadingDropdownSupervisorEdit: false,
            loadingDropdownLevelAdd: false,
            loadingDropdownLevelEdit: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownSupervisorAdd: null,
            dataDropdownSupervisorEdit: null,
            dataDropdownLevelAdd: null,
            dataDropdownLevelEdit: null,
            dataDropdownSupervisorType: [
                {name: 'ASPS', code: 'ASPS'},
                {name: 'ASPM', code: 'ASPM'},
            ],
            dataDropdownStatus: [
                {name: 'Aktif', code: 'y'},
                {name: 'Nonaktif', code: 'n'},
            ],

            // addNew
            forms: {
                username: null,
                name: null,
                password: "viewer",
                phone: null,
                email: null,
                level_id: null,
                spv_type: null,
                spv_id: null,
            },
            visibleSpvAdd: false,
            visibleSpvEdit: false,

            // edit
            item: {},
            editItemDialog: false,
            password: null,
            
            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownSupervisor('');
        this.searchDropdownLevel('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        'forms.level_id.level_name'(newValue) {
            this.forms.spv_type = null;
            if (newValue === 'SPV') {
                this.visibleSpvAdd = true;
            } else {
                this.visibleSpvAdd = false;
            }
        },
        'item.mcu_level_id'(newValue) {
            // this.item.spv_type = null;
            if (newValue === 3) {
                this.visibleSpvEdit = true;
            } else {
                this.visibleSpvEdit = false;
            }
        }
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownLevel(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dlevel.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownLevelAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownLevelEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/mcu-level',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownLevelAdd = res.data.data;
                        this.loadingDropdownLevelAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownLevelEdit = res.data.data;
                        this.loadingDropdownLevelEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownLevelAdd = res.data.data;
                        this.dataDropdownLevelEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownSupervisor(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dspv.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownSupervisorAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownSupervisorEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/spv',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                        "level_id" : purpose == "add" ? this.forms.level_id?.level_id : this.item.mcu_level_id,
                        "spv_type" : purpose == "add" ? this.forms.spv_type : this.item.spv_type,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownSupervisorAdd = res.data.data;
                        this.loadingDropdownSupervisorAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownSupervisorEdit = res.data.data;
                        this.loadingDropdownSupervisorEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownSupervisorAdd = res.data.data;
                        this.dataDropdownSupervisorEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeSpvAdd(){
            this.forms.spv_id = null;
            this.searchDropdownSupervisor('');
        },
        changeSpvEdit(){
            this.item.spv_id = null;
            this.searchDropdownSupervisor('','edit');
        },
        changeSpvLevelEdit(){
            this.item.spv_type = null;
            this.changeSpvEdit();
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userhp/create',
                data: {
                    "username": this.forms.username,
                    "name": this.forms.name,
                    "password": this.forms.password,
                    "phone": this.forms.phone,
                    "email": this.forms.email,
                    "level_id": this.forms.level_id ? this.forms.level_id.level_id : null,
                    "spv_type": this.forms.spv_type,
                    "spv_id": this.forms.spv_id,
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.username = null;
            this.forms.name= null;
            this.forms.password= "viewer";
            this.forms.phone= null;
            this.forms.email= null;
            this.forms.level_id= null;
            this.forms.spv_type= null;
            this.forms.spv_id= null;
        },
        // RESET
		resetItem(item) {
			this.item = item;
            
            this.$refs.reset.resetItemDialog = true;
		},
        // EDIT
		editItem(item) {
			this.item = item;
            this.password = null,

            this.item.spv_type = this.item.spv_type ? this.item.spv_type.toUpperCase() : null; 
            
            // dropdown 
            this.searchDropdownLevel('', 'edit', item.mcu_level_name);
            this.searchDropdownSupervisor('', 'edit', item.spv_code +'-'+item.spv_name);

			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userhp/update',
                data: {
                    "userid" : this.item.userid,
                    "username" : this.item.username,
                    "name" : this.item.mcu_name,
                    "phone" : this.item.mcu_phone,
                    "email" : this.item.mcu_email,
                    "level_id" : this.item.mcu_level_id,
                    "spv_type" : this.item.spv_type,
                    "spv_id" : this.item.spv_id,
                    "status" : this.item.mcu_status,
                    "password" : this.password,
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userhp',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-userhp/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master User Hp Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>